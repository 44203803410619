<template>
  <!-- <div class="input-group mb-3">
    <div class="input-group-prepend">
      <button type="button" class="btn btn-primary btn-icon height-35" @click="toggleVisibility">
        <i :class="{zmdi: true, 'zmdi-eye': !show, 'zmdi-eye-off': show }"></i>
      </button>
    </div>
    <input
      type="text"
      class="form-control"
      :value="show ? value : new Array(value.length > 25 ? 25 : value.length).join('●')"
      :readonly="readonly"
      ref="hiddenField"
      aria-label
      aria-describedby="basic-addon1"
      @click="select()"
    />
  </div> -->

  <div class="input-group mb-3">
    <button class="btn btn-primary btn-icon btn-outline-primary" type="button" @click="toggleVisibility">
      <i :class="{fas: true, 'fa-eye': !show, 'fa-eye-slash': show }"></i>
    </button>
    <input
      type="text"
      class="form-control"
      :value="show ? value : new Array(value.length > 25 ? 25 : value.length).join('●')"
      :readonly="readonly"
      ref="hiddenField"
      aria-label
      aria-describedby="basic-addon1"
      @click="select()"
    />
    <!-- <button class="btn btn-primary btn-icon btn-outline-primary" type="button" @click="toggleVisibility">
      <i :class="{zmdi: true, 'zmdi-eye': !show, 'zmdi-eye-off': show }"></i>
    </button> -->
  </div>
</template>
<script>
// • ●
export default {
  name: 'HiddenInput',
  props: ['value', 'title', 'readonly'],
  mounted() {
    console.log(this.value);
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggleVisibility() {
      this.show = !this.show;
    },
    select() {
      if (this.show) {
        this.$refs.hiddenField.setSelectionRange(0, this.value.length);
      }
    },
    // async copyValue() {
    //   this.$toast.show({
    //     title: this.$t('generic-str.success'),
    //     content: this.title,
    //     type: 'success',
    //   });

    //   this.$clipboard(this.value);
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.btn {
  border-radius: .25rem 0 0 .25rem;
}
</style>
